//Bootstrap copys
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.text-start {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: right !important;
}

.bold{
  font-weight: 500;
}

.d-flex{
  display: flex;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.m-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.m-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.m-3{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.fl-r{
  float: right;
}

.full-width {
  width: 100%;
}

//grid
.brake-word > div{
  word-wrap: break-word;
  word-break: break-all;
}
