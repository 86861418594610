// @use '@angular/material' as mat;

// .mat-mdc-table {
//   width: 100%;
// }

.mat-mdc-form-field{
  margin-right: 1em;
}

button.mat-mdc-raised-button {
  margin: 0.3rem;
}

button.mat-mdc-raised-button.green-button{
  background-color: #4CAF50;
  color: white;
}

.mat-mdc-input-element.narrow{
  max-width: 4rem;
}

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}
