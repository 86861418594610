@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.define-palette(mat.$indigo-palette);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$candy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

@import 'styles/helpers.scss';
@import 'styles/colors.scss';
@import 'styles/mat-overides.scss';

.component {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

//standard layouts

//Standrad styles

//hedder with button
.btn-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

span.search-highlight {
  font-weight: bold;
}


.no-price-in-db{
  padding: 0.3rem;
  background-color: yellow;
}

.no-trust{
  padding: 0.3rem;
  background-color: orange;
}

.vertical-align {
  display: flex;
  align-items: center;
  /* align vertical */
}

.center-align {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
}
